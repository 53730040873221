@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: -webkit-fill-available;
}
body {
    display: grid;
    grid-auto-rows: 5rem auto 5rem;
    height: 100vh;
    margin: 0;
    padding: 0;
}
main {
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: #DC2626 #E5E7EB;
}
.dialog {
    position: fixed;
    place-items: center;
    height: 100%;
    width: 100%;
    z-index: 98;
    background-color: rgba(0, 0, 0, 0.8);
}
.popinanimation {
    animation-duration: 0.5s;
    animation-name: popin;
}
@keyframes popin {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}
#questions {
    z-index: 99;
}
main::-webkit-scrollbar {
    width: 0.30rem;
}
main::-webkit-scrollbar-thumb {
    background: #DC2626;
    border-radius: 5px;
}
main::-webkit-scrollbar-track {
    background: #E5E7EB;
}